function Info({ data }) {
  return (
    <div className="projects section">
      <h3 className="project-section-title">Projects</h3>
      {data.sites.map((site) => {
        return (
          <dl className="project" key={site.name}>
            <dt className="project-title">{site.name}</dt>
            <dd className="project-link">
              <a href={site.url} className="link">
                {site.url}
              </a>
            </dd>
            <dd className="project-description">
              {site.description}
            </dd>
          </dl>
        );
      })}
    </div>
  );
}

export default Info;
