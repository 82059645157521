import { devIcons } from "../icons";

function Info({ data }) {
  return (
    <div className="experience section">
      <h3 className="experience-section-title">Experience</h3>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.tools.map((tool) => {
          return (
            <span className="tool icon" key={tool}>
              {devIcons[tool]}
            </span>
          );
        })}
      </div>
      <dl>
        {data.jobs.map((job) => {
          return (
            <span className="job" key={job.company}>
              <dt className="job-company">
                <span style={{ float: "left" }}>{job.company}</span>
                <span style={{ float: "right" }}>{job.time}</span>
              </dt>
              <br />
              <dd className="job-title">Title - {job.title}</dd>
              <dd style={{ display: "flex", flexWrap: "wrap" }}>
                {job.langs.map((lang) => {
                  return (
                    <span className="lang icon" key={lang}>
                      {devIcons[lang]}
                    </span>
                  );
                })}
              </dd>
              <dd className="job-projects">
                <ul>
                  {job.projects.map((project) => {
                    return (
                      <li key={project.name}>
                        <dl>
                          <dt>{project.name}</dt>
                          <dd>{project.description}</dd>
                        </dl>
                      </li>
                    );
                  })}
                </ul>
              </dd>

              {/* <p className="project-description">{job.description}</p> */}
            </span>
          );
        })}
      </dl>
    </div>
  );
}

export default Info;
