function Header({data}) {
  return(
    <header>
      <div className="header-grid">
        <div className="header-column">
          <h1 className="name-header">{data.name}</h1>
          <h2 className="title-subheader">{data.title}</h2>
        </div>
        <div className="header-column">
          <div className="image-wrapper">
            {/* <img src="/images/profile.jpg" alt="profile pic" /> */}
            <img src="/images/square_profile.jpg" alt="profile pic" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
