import { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Info from './components/Info';
import Experience from "./components/Experience";
import Projects from './components/Projects';

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    fetch("/profileData.json")
      .then(res => res.json())
      .then(
        (result) => {
          setData(result);
        },
        (error) => {
          setData(null);
          console.log(error);
        }
      )
  }

  return (!data) ? '' : (
    <div className="App">
      <div className="heading-background sm-none"></div>
      <div className="heading-border sm-none"></div>
      <div className="page-content">
        <Header data={data.header} />
        <Info data={data.info} />
        <Experience data={data.experience} />
        <Projects data={data.projects} />
      </div>
    </div>
  );
}

export default App;
