function Info({data}) {
  return(
    <div className="info section">
      <ul className="list">
        <li><i className="fa fa-graduation-cap icon"></i>{data.education}</li>
        <li><i className="fa fa-map-marker icon"></i>{data.location}</li>
        <li><a href={data.resume} className="link"><i className="fa fa-download icon"></i>Résumé</a></li>
      </ul>
      <ul className="list">
        <li><a href={"mailto:" + data.email} className="link"><i className="fa fa-envelope icon"></i>{data.email}</a></li>
        <li><a href={data.linkedin} className="link"><i className="fa fa-linkedin icon"></i>LinkedIn</a></li>
        {/* <li><a href={data.github} className="link"><i className="fa fa-github icon"></i>Github</a></li> */}
      </ul>
    </div>
  );
}

export default Info;
