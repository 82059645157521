export const fontAwsomeIcons = {
  html: <i alt="" title="" className="fa-brands fa-html5"></i>,
  css: <i alt="" title="" className="fa-brands fa-css3-alt"></i>,
  js: <i alt="" title="" className="fa-brands fa-js"></i>,
  react: <i alt="" title="" className="fa-brands fa-react"></i>,
  node: <i alt="" title="" className="fa-brands fa-node-js"></i>,
};

export const devIcons = {
  html: (
    <i
      alt="html"
      title="html"
      className="devicon-html5-plain-wordmark colored"
    ></i>
  ),
  css: (
    <i
      alt="css"
      title="css"
      className="devicon-css3-plain-wordmark colored"
    ></i>
  ),
  javascript: (
    <i
      alt="javascript"
      title="javascript"
      className="devicon-javascript-plain colored"
    ></i>
  ),
  react: (
    <i
      alt="react"
      title="react"
      className="devicon-react-original-wordmark colored"
    ></i>
  ),
  node: (
    <i alt="node" title="node" className="devicon-nodejs-plain colored"></i>
  ),
  flutter: (
    <i
      alt="Flutter"
      title="Flutter"
      className="devicon-flutter-plain colored"
    ></i>
  ),
  dart: <i alt="Dart" title="Dart" className="devicon-dart-plain colored"></i>,
  typescript: (
    <i
      alt="TypeScript"
      title="TypeScript"
      className="devicon-typescript-plain colored"
    ></i>
  ),
  nextjs: (
    <i
      alt="nextjs"
      title="nextjs"
      className="devicon-nextjs-original-wordmark colored"
    ></i>
  ),
  nestjs: (
    <i
      alt="nestjs"
      title="nestjs"
      className="devicon-nestjs-plain-wordmark colored"
    ></i>
  ),
  handlebars: (
    <i
      alt="handlebars"
      title="handlebars"
      className="devicon-handlebars-plain-wordmark"
    ></i>
  ),
  googlecloud: (
    <i
      alt="google cloud"
      title="google cloud"
      className="devicon-googlecloud-plain colored"
    ></i>
  ),
  kubernetes: (
    <i
      alt="kubernetes"
      title="kubernetes"
      className="devicon-kubernetes-plain-wordmark colored"
    ></i>
  ),
  firebase: (
    <i
      alt="Firebase"
      title="Firebase"
      className="devicon-firebase-plain-wordmark colored"
    ></i>
  ),
  mongodb: (
    <i
      alt="mongoDB"
      title="mongoDB"
      className="devicon-mongodb-plain-wordmark colored"
    ></i>
  ),
  graphql: (
    <i
      alt="GraphQL"
      title="GraphQL"
      className="devicon-graphql-plain-wordmark colored"
    ></i>
  ),
  mysql: (
    <i alt="MySQL" title="MySQL" className="devicon-mysql-plain-wordmark"></i>
  ),
  sqlite: (
    <i
      alt="SQLite"
      title="SQLite"
      className="devicon-sqlite-plain-wordmark colored"
    ></i>
  ),
  electron: (
    <i
      alt="electron"
      title="electron"
      className="devicon-electron-original"
    ></i>
  ),
  cplusplus: (
    <i alt="c++" title="c++" className="devicon-cplusplus-plain colored"></i>
  ),
  csharp: (
    <i
      alt="c sharp"
      title="c sharp"
      className="devicon-csharp-plain-wordmark colored"
    ></i>
  ),
  jquery: (
    <i
      alt="jquery"
      title="jquery"
      className="devicon-jquery-plain-wordmark colored"
    ></i>
  ),
  apple: (
    <i alt="apple" title="apple" className="devicon-apple-original colored"></i>
  ),
  androidstudio: (
    <i
      alt="android studio"
      title="android studio"
      className="devicon-androidstudio-plain colored"
    ></i>
  ),
  xcode: (
    <i alt="xcode" title="xcode" className="devicon-xcode-plain colored"></i>
  ),
  jira: <i alt="jira" title="jira" className="devicon-jira-plain colored"></i>,
  circleci: (
    <i
      alt="circleci"
      title="circleci"
      className="devicon-circleci-plain-wordmark"
    ></i>
  ),
  vscode: (
    <i alt="vscode" title="vscode" className="devicon-vscode-plain colored"></i>
  ),
  docker: (
    <i
      alt="docker"
      title="docker"
      className="devicon-docker-plain-wordmark colored"
    ></i>
  ),
  git: (
    <i alt="git" title="git" className="devicon-git-plain-wordmark colored"></i>
  ),
  github: (
    <i
      alt="github"
      title="github"
      className="devicon-github-original-wordmark"
    ></i>
  ),
  npm: (
    <i
      alt="npm"
      title="npm"
      className="devicon-npm-original-wordmark colored"
    ></i>
  ),
  visualstudio: (
    <i
      alt="visual studio"
      title="visual studio"
      className="devicon-visualstudio-plain colored"
    ></i>
  ),
  slack: <i alt="slack" title="slack" className="devicon-slack-plain"></i>,
  figma: (
    <i
      alt="figma"
      title="figma"
      className="devicon-figma-plain-wordmark colored"
    ></i>
  ),
  aws: (
    <i
      alt="aws"
      title="aws"
      className="devicon-amazonwebservices-plain colored"
    ></i>
  ),
};
